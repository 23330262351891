<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  created(){
    //英
    // localStorage.setItem('type', 1)
    // localStorage.setItem("language", "en_US");
    //日
    localStorage.setItem('type', 2)
    localStorage.setItem("language", "ja_CN");
    this.$i18n.locale = localStorage.getItem("language");
  },
  // mounted(){
  //   // console.log(document.documentElement.clientWidth);
  // },
  async mounted() {
    // 从接口获取数据
    const response = await fetch('https://webadmin.apitor.com/api/keywords/keywords');
    const data = await response.json();
    document.title = data.data.title;
    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    if (keywordsMeta) {
      keywordsMeta.setAttribute('content',data.data.keywords);
    }
    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
      descriptionMeta.setAttribute('content', data.data.content);
    }
    const googleSiteVerification = document.querySelector('meta[name="google-site-verification"]');
    if (googleSiteVerification) {
      googleSiteVerification.setAttribute('content', data.data.google);
    }
  }
}
</script>

<style>
/* 英 */
/* @font-face{
  font-family: font-name;
  src: url('@/assets/Montserrat-VariableFont_wght.ttf')
} */
/* 日 */  
@font-face{ 
  font-family: font-name;
  src: url('@/assets/NotoSansJP-VariableFont_wght.ttf')
} 
@font-face{
  font-family: font-name1;
  src: url('@/assets/Montserrat-VariableFont_wght.ttf')
}
@font-face{
  font-family: font-name2;
  src: url('@/assets/NotoSansJP-VariableFont_wght.ttf')
}
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
</style>
